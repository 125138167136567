<template lang="pug">
	.border-rounded.position-relative.find-out(v-if="isShow")
		icon-close.find-out__close(@click="close")
		h3.b1.text-bold.text-primary.find-out__title {{ $t('findOut.title') }}
		ul.ul-nav.find-out__list
			li.b3.text-grey.position-relative.find-out__item(v-for='element in list' :key='element' :class="{'is-dark': isDarkColor}" v-html="element")
			li.b3.text-grey.position-relative.find-out__item.find-out__other-points(v-html="$t('findOut.otherPoints')")
		.find-out__image
			.find-out__image-books(style="background: url('/assets/img/dashboard/books-1.webp') center / contain no-repeat")

</template>

<script>
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'FindOut',
    components: {
        IconClose,
    },
    data: () => ({
        isShow: false,
        list: null,
    }),
    created() {
        this.isShow = !this.$cookies.get('cookies-findOut');
        this.list = this.$t('findOut.list');
    },
    methods: {
        close() {
            this.isShow = false;
            this.$cookies.set('cookies-findOut', '1', '1Y');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.find-out {
    padding: 19px;
    margin-bottom: 20px;
    border: 1px solid var(--grayscale-dark-line);
    background-color: var(--primary-bg-color);
    overflow: hidden;

    @include respond-below(sm) {
        margin-top: 20px;
        padding: 14px 16px;
        margin-bottom: 0;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__title {
        margin-bottom: 5px !important;

        @include respond-below(xs) {
            margin-bottom: 10px !important;
        }
    }

    &__list {
        width: 100%;
        position: relative;
        z-index: 1;

        @include respond-below(xxs) {
            margin-bottom: 42px;
        }
    }

    &__item {
        padding-left: 21px;

        &:before {
            content: '';
            display: block;
            width: 3.5px;
            height: 3.5px;
            position: absolute;
            left: 9px;
            top: 11px;
            background: center / contain no-repeat
                url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='3.4' height='3.4' rx='1.7' fill='%238292A1'/%3E%3C/svg%3E%0A");
        }

        &.is-dark {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='3.4' height='3.4' rx='1.7' fill='%23808080'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__other-points {
        width: calc(100% - 93px);

        @include respond-below(sm) {
            width: calc(100% - 105px);
        }

        @include respond-below(xxs) {
            width: 100%;
        }

        @media (max-width: 335px) {
            max-width: 240px;
        }
    }

    &__image {
        border-radius: 50%;
        width: 117px;
        height: 117px;
        background-color: #fff;
        position: absolute;
        bottom: -28px;
        right: -7px;

        @include respond-below(xxs) {
            bottom: -45px;
            right: 16px;
        }

        &-books {
            position: absolute;
            top: -20px;
            right: -37px;
            width: 128px;
            height: 95px;

            @include respond-below(xxs) {
                top: -1px;
                right: -52px;
            }
        }
    }
}
</style>
