<template lang="pug">
	.d-flex.flex-column.card-basic.activity
		span.b1 {{ $t('project.activity') }}

		.d-flex.flex-column.mx-auto.mt-3(v-if="lastActivity.length == 0")
			h1.mb-4.text-center 😢
			span.b3.text-grey {{ $t('project.notActiveYet') }}

		.d-flex.flex-column.activity__list(v-if="lastActivity.length > 0")
			.d-flex.activity__item(v-for="(item, i) in lastActivity" :key="`item-${i}`" :class="item.tokens && !item.paymentInterval ? 'tokenPurchase' : item.type")
				.position-relative.activity__avatar
					img.activity__avatar__img(v-if='item.avatar && item.avatar.contentUrl' :src="item.avatar.contentUrl")
					.activity__avatar__img.activity__avatar__empty(v-else)
						.activity__avatar__empty__text {{ isLocaleRu ? 'H' : 'U'}}
					.activity__wrap-icon
						.activity__status-icon
							.activity__status-smiley(v-if="item.tokens && !item.paymentInterval") 💎

				.d-flex.flex-column.flex-md-row.w-100.activity__inner
					.d-flex.flex-column.mr-auto
						.d-flex.align-items-center
							span.b1(v-if="item.tokens && !item.paymentInterval") {{ item.type == 'cancelled' ? $t(`project.statusSub.cancellationTokenPurchase`) : $t(`project.statusSub.tokenPurchase`) }}
							span.b1(v-else) {{ $t(`project.statusSub.${item.type}`) }}
							span.b4.text-primary.activity__discount(
								v-if="item.promotion"
								v-html="`-${item.promotion.discount}%`"
								:id="!isMobile ? `promotionTooltip-${item.id}` : ''"
								@click="isMobile ? $bvModal.show(`promotionTooltip-${item.id}`) : ''"
							)
							span.b4.text-primary.activity__discount(
								v-if="item.promoCode"
								v-html="`-${item.promoCode.discount}%`"
								:id="!isMobile ? `promoCodeTooltip-${item.id}` : ''"
								@click="isMobile ? $bvModal.show(`promoCodeTooltip-${item.id}`) : ''"
							)

							span.b4.text-success.activity__discount.activity__discount--extra-price(
								v-if="item.extraPrice && item.extraPrice.amount > 0"
								v-html="`+${item.extraPrice.priceWithCurrency()}`"
								:id="!isMobile ? `overPriceTooltip-${item.id}` : ''"
								@click="isMobile ? $bvModal.show(`overPriceTooltip-${item.id}`) : ''"
							)

							span.b4.activity__discount.activity__discount--comebacker(
								v-if="item.comebacker"
								v-html="`-${item.comebacker.discount}%`"
								:id="!isMobile ? `comebackerTooltip-${item.id}` : ''"
								@click="isMobile ? $bvModal.show(`comebackerTooltip-${item.id}`) : ''"
							)

							modal-tooltip(
								v-if="item.promotion"
								:id="`promotionTooltip-${item.id}`"
								:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/akcii'"
								:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('promotions.tabs.stock')}`"
								:title="$t('plan.purchasedPromotion')"
								:classTooltip="'promotion-tooltip'"
								:text="`${$t('paymentConfirm.promotion')}: ${item.promotion.title}`"
								:textMobile="item.promotion.title"
							)

							modal-tooltip(
								v-if="item.promoCode"
								:id="`promoCodeTooltip-${item.id}`"
								:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/promokody'"
								:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('promotions.tabs.promoCodes')}`"
								:title="$t('plan.promoCodeUsed')"
								:classTooltip="'promotion-tooltip'"
								:text="`${$t('paymentPage.promoCode')}: ${item.promoCode.title || item.promoCode.code}`"
								:textMobile="item.promoCode.title || item.promoCode.code"
							)

							modal-tooltip(
								v-if="item.extraPrice && item.extraPrice.amount > 0"
								:id="`overPriceTooltip-${item.id}`"
								:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#platyat-bolshe'"
								:textLink="`<svg class='mr-2' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('tutorials.payMore.title')}`"
								:title="$t('plan.userPaysMore')"
								:classTooltip="'promotion-tooltip is-overprice'"
								:text="$t('tutorials.payMore.desc2')"
								:textMobile="$t('tutorials.payMore.desc3')"
							)

							modal-tooltip(
								v-if="item.comebacker"
								:id="`comebackerTooltip-${item.id}`"
								:classTooltip="'promotion-tooltip is-comebacker'"
								:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/comebacker'"
								:textLink='`<span class="icon"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.15686 13.6489C4.91853 12.8514 4.7627 11.9714 4.7627 10.9998C4.7627 6.60893 8.31936 3.05227 12.7102 3.05227C17.101 3.05227 20.6577 6.60893 20.6577 10.9998C20.6577 15.3906 17.101 18.9473 12.7102 18.9473C11.0785 18.9473 9.55686 18.4523 8.29186 17.6089" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.37634 14.7852L2.33301 12.136" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M5.37695 14.7852L7.63195 11.696" stroke="#B971F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span>${$t("promotions.tabs.comebacker")}`'
								:title="$t('plan.usedComebacker')"
								:text="`${$t('promotions.tabs.comebacker')}: ${item.comebacker.title}`"
								:textMobile="item.comebacker.title"
							)

						.text-grey.activity__info
							span.b2 {{ item.createdAt | byDay }}
							span.b2 {{ item.tgUsername ? item.tgUsername : $t('statuses.unknown') }}
							span.b2.overflow-wrap.mr-md-1(v-if="item.tokens && !item.paymentInterval") {{ `${item.type === 'cancelled' ? '-' : ''}${item.tokens} ${$t('tokens.quantityTokens')}` }}
							span.b2.overflow-wrap.mr-md-1(v-else) {{ getTitle(item.title) }}
					.d-flex.order-5.order-md-0.activity__tooltip-wrap(v-if="item.isRecover || item.wasTrial")
						span.activity__tooltip.status-health(v-if="item.isRecover" :id="!isMobile ? `restored-${item.id}` : ''" @click="isMobile ? $bvModal.show(`restored-${item.id}`) : ''")
						span.activity__tooltip.status-dollar-circle(v-if="item.wasTrial" :id="!isMobile ? `actively-${item.id}` : ''" @click="isMobile ? $bvModal.show(`actively-${item.id}`) : ''")

						modal-tooltip(
							:id="`restored-${item.id}`"
							:href="'https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok'"
							:textLink="`<div class='status-health bg-white mr-2'></div>&nbsp${$t('tutorials.recoverySubscription')}`"
							:title="$t('tutorials.subscriptionRestored')"
							:classTooltip="'dashboard-tooltip'"
							:text="$t('tutorials.subscriptionRestored')"
						)

						modal-tooltip(
							:id="`actively-${item.id}`"
							:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#probnyi-period'"
							:textLink="`<svg style='margin-right: 5px;' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path stroke='#52A7F9' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6ZM15.75 9h-7.5M15.75 15h-7.5'/></svg>${$t('plan.free')}`"
							:title="$t('tutorials.successfulConversion')"
							:classTooltip="'dashboard-tooltip is-trial'"
							:text="$t('tutorials.trialSubscriptionActive')"
							:textMobile="$t('tutorials.trialSubscriptionActive')"
						)

					.d-flex.flex-md-column.flex-end.ml-md-2.activity__term
						span.b1.text-nowrap.text-right.activity__price(:class="{'cancelled-tokens': item.tokens && !item.paymentInterval && item.type == 'cancelled'}" v-html="`${item.type == 'cancelled' || item.type == 'refund'? '-' : ''}${item.totalPrice.priceWithCurrency()}`")
						span.b4.text-grey.text-nowrap.text-right.mt-auto.mt-md-0.ml-2.ml-md-0(v-if="item.period && item.paymentInterval") {{ $t(`plan.periods.${item.period.title}`) }}

		.d-flex.justify-content-start
			a.b2.text-primary.mt-2(v-if='needMore && lastActivity.length > 0 && !isloading' @click='showMore' href='javascript:void(0)') {{ $t('table.showMore') }}
		.loader.primary.my-4.mx-auto(v-if="isloading && lastActivity.length > 0")
</template>

<script>
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Activity',
    components: {
        ModalTooltip,
    },
    props: {
        id: {
            type: [String, Number],
            default: '0',
        },
    },
    data: () => ({
        page: 1,
        itemsPerPage: 10,
        lastActivity: [],
        isloading: false,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            items: 'project/lastActivity',
            itemsTotal: 'project/totalLastActivity',
        }),
        needMore() {
            return this.lastActivity.length < this.itemsTotal;
        },
    },
    mounted() {
        this.getItems();
    },
    methods: {
        ...mapActions({
            get: 'project/lastActivity',
        }),
        getItems() {
            this.isloading = true;
            this.get({ id: this.id, itemsPerPage: this.itemsPerPage, page: this.page }).then(v => {
                if (this.page == 1) this.lastActivity = [];

                this.lastActivity = this.lastActivity.concat(v);

                this.isloading = false;
            });
        },
        showMore() {
            this.page++;
            this.getItems();
        },
        getTitle(title) {
            if (title == 'Annual') {
                return this.$t('date.year').toLowerCase();
            } else if (title == 'Monthly') {
                return this.$t('date.month').toLowerCase();
            } else if (title == 'Quarterly') {
                return this.$t('date.threeMonths').toLowerCase();
            }

            return title;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.activity {
    &__avatar {
        width: 45px;
        min-width: 45px;
        height: 45px;
        min-height: 45px;
        border-radius: 50%;
        margin-right: 14px;

        &__img {
            height: 45px;
            width: 45px;
            border-radius: 50%;
        }

        &__empty {
            background: #e4e7ea;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cbd2d8;
            font-size: 24px;
            font-weight: 600;
        }
    }

    &__wrap-icon {
        position: absolute;
        bottom: -1.5px;
        right: -6.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 3px solid var(--foreground-color);
    }

    &__status-icon {
        min-width: 17px;
        min-height: 17px;
        background-color: var(--foreground-color);
        mask-position: center;
        mask-repeat: no-repeat;
    }

    &__tooltip-wrap {
        margin-right: -3.5px;
        margin-top: 7px;

        @include respond-below(sm) {
            margin: 10px 0 0 -7px;
        }
    }

    &__tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        min-width: 34px;
        height: 34px;
        min-height: 34px;
        border-radius: 50%;
        background-color: var(--bg-back);
        margin: 0 7.5px;
        cursor: pointer;
    }

    &__discount {
        width: max-content;
        padding: 1px 6.5px 2px;
        border-radius: 6px;
        background-color: var(--brand-bg-primary);
        cursor: pointer;
        margin-left: 5px;

        &--extra-price {
            background-color: var(--brand-bg-success);
        }

        &--comebacker {
            color: var(--brand-light-gradient-secondary) !important;
            background-color: var(--brand-bg-discount);
        }
    }

    &__price {
        font-weight: 600;
        margin-top: 1px;

        &.cancelled-tokens {
            color: var(--brand-error) !important;
        }
    }

    &__item {
        display: inline-flex;
        padding: 30px 37px 28px 20px;
        border-bottom: 1px solid var(--grayscale-dark-line);

        &:last-child {
            border-bottom: none;
        }

        @include respond-below(sm) {
            align-items: start;
            padding: 15px 0;
            margin: 0;
        }

        &.new_trial {
            .activity__wrap-icon {
                background-color: var(--brand-primary);
            }

            .activity__status-icon {
                mask-image: url('/assets/img/icons/activity/check.svg');
                mask-size: 68%;
                mask-position: 3px;
            }

            .activity__price {
                color: var(--brand-primary);
            }
        }

        &.new {
            .activity__wrap-icon {
                background-color: var(--brand-success);
            }

            .activity__status-icon {
                mask-image: url('/assets/img/icons/activity/check.svg');
                mask-size: 68%;
                mask-position: 3.5px;
            }

            .activity__price {
                color: var(--brand-success);
            }
        }

        &.cancelled {
            .activity__wrap-icon {
                background-color: var(--grayscale-color-grey);
            }

            .activity__status-icon {
                mask-image: url('/assets/img/icons/activity/unsub.svg');
                mask-size: 50%;
                mask-position: 4.5px;
            }

            .activity__price {
                color: var(--grayscale-color-grey);
            }
        }

        &.recurrent {
            .activity__wrap-icon {
                background-color: var(--brand-bg-darkned-success);
            }

            .activity__status-icon {
                mask-image: url('/assets/img/icons/activity/extension.svg');
                mask-size: 59%;
                mask-position: 3px 2.5px;
            }

            .activity__price {
                color: var(--brand-bg-darkned-success);
            }
        }

        &.refund {
            .activity__wrap-icon {
                background-color: var(--brand-error);
            }

            .activity__status-icon {
                mask-image: url('/assets/img/icons/activity/return.svg');
                mask-size: 66%;
                mask-position: 3px 4px;
            }

            .activity__price {
                color: var(--brand-error);
            }
        }

        &.tokenPurchase {
            .activity__wrap-icon {
                background-color: var(--foreground-color);
            }

            .activity__status-icon {
                border: 0.2px solid var(--light-gray);
                border-radius: 50%;
                position: relative;
            }

            .activity__status-smiley {
                font-size: 8px;
                position: absolute;
                left: 2.5px;
            }

            .activity__price {
                color: var(--brand-success);
            }
        }
    }

    &__inner {
        margin-top: -2px;
    }

    &__term {
        margin-top: 3px;
    }

    &__info {
        span {
            position: relative;
            padding-right: 12px;

            &::after {
                content: '';
                position: absolute;
                top: 9px;
                right: 6px;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: var(--grey-text-color);
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }
        }
    }

    &.new_trial {
        .activity__status-icon {
            mask-image: url('/assets/img/icons/activity/check.svg');
            background-color: var(--brand-primary);
        }

        .activity__price {
            color: var(--brand-primary);
        }
    }
}
</style>
