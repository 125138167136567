<template lang="pug">
	.d-flex.flex-column.basic-box-shadow.border-rounded.foreground-color.news(v-if="isShow")
		svg.news__decor.news__decor--top(xmlns="http://www.w3.org/2000/svg" width="23" height="29" viewBox="0 0 23 29" fill="none")
			circle(opacity="0.2" cx="0.5" cy="6.5" r="22" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 10")
		svg.news__decor.news__decor--bottom(xmlns="http://www.w3.org/2000/svg" width="89" height="66" viewBox="0 0 89 66" fill="none")
			circle(opacity="0.2" cx="53.5" cy="53.5" r="53" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 10")
		icon-close.news__close(@click="close")
		p.b1.news__title {{ $t('news.title') }}
		p.b3.text-grey.news__txt {{ $t('news.desc') }}

		.d-flex.news__social(v-if="socials && !isNeuro && !isLocaleRu")
			a.news__link(v-for="(social, i) in socials" :key="`social-${i}`" :href="social.href" target="_blank")
				img.news__image(:src="social.src" :alt="social.name")

		.d-flex.news__social(v-if="socialsRu && !isNeuro && isLocaleRu")
			a.news__link(v-for="(social, i) in socialsRu" :key="`social-${i}`" :href="social.href" target="_blank")
				img.news__image(:src="social.src" :alt="social.name")

		.d-flex.news__social(v-if="socialsNeuro && isNeuro && !isLocaleRu")
			a.news__link(v-for="(social, i) in socialsNeuro" :key="`social-${i}`" :href="social.href" target="_blank")
				img.news__image(:src="social.src" :alt="social.name")

		.d-flex.news__social(v-if="socialsNeuroRu && isNeuro && isLocaleRu")
			a.news__link(v-for="(social, i) in socialsNeuroRu" :key="`social-${i}`" :href="social.href" target="_blank")
				img.news__image(:src="social.src" :alt="social.name")
</template>

<script>
import IconClose from '@/components/Common/IconClose';
import { socials, socialsRu, socialsNeuro, socialsNeuroRu } from '@/constants/config';

export default {
    name: 'News',
    components: {
        IconClose,
    },
    props: {
        isNeuro: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isShow: true,
    }),
    created() {
        this.isShow = !this.$cookies.get('cookies-news');
    },
    computed: {
        socials() {
            return socials.apply(this);
        },
        socialsRu() {
            return socialsRu.apply(this);
        },
        socialsNeuro() {
            return socialsNeuro.apply(this);
        },
        socialsNeuroRu() {
            return socialsNeuroRu.apply(this);
        },
    },
    methods: {
        close() {
            this.isShow = false;
            this.$cookies.set('cookies-news', '1', '1Y');
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.news {
    position: relative;
    padding: 20px;
    overflow: hidden;

    @include respond-below(sm) {
        padding: 15px 17px;
    }

    p {
        max-width: percentage(375/420);

        @include respond-below(sm) {
            max-width: 100%;
        }
    }

    &__title {
        margin-bottom: 5px !important;

        @include respond-below(xs) {
            margin-bottom: 10px !important;
        }
    }

    &__txt {
        margin-bottom: 17px !important;

        @include respond-below(xs) {
            margin-bottom: 15px !important;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__link {
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        transition: var(--animation-time-short);
        margin-right: 15px;
        border-radius: 50%;

        @include respond-below(sm) {
            width: 26px;
            min-width: 26px;
            height: 26px;
            min-height: 26px;
        }

        &:hover,
        &:active {
            transform: scale(1.2);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__social {
        @include respond-below(sm) {
            justify-content: space-between;
            max-width: 500px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__decor {
        position: absolute;

        &--top {
            top: 0;
            left: 0;
        }

        &--bottom {
            right: 0;
            bottom: -42px;

            @include respond-below(sm) {
                right: -20px;
                bottom: -22px;
                transform: rotate(-45deg);
            }
        }
    }
}
</style>
