<template lang="pug">
	.d-flex.flex-column.card-basic.activity-statistics(v-if='activity')
		.d-flex.justify-content-between.align-items-center.flex-wrap
			span.b1.mr-2.mb-2.mb-xl-0 {{ $t('project.activism') }}

			.d-flex
				.d-flex.position-relative.date-picker-control.date-picker-control--small
					v-date-picker.w-100.date-picker.date-picker--small(@input='changeDate' v-model="date" :min-date="new Date(item.createdAt)" :max-date="new Date()" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }")
						template(v-slot="{ inputValue, inputEvents, togglePopover }")
							span.icon-calendar(@click="togglePopover()")
							.d-flex.date-picker__input(@click="togglePopover()")
								input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !date.start && !date.end}" id="creation-date-calendar" :value="date.start | dateDDMMMYYDots" v-on="inputEvents.start" readonly)
								span.b3.mr-1(v-if="date.start && date.end") -
								span.b3.date-picker__input-calendar(v-if="date.start && date.end") {{ date.end | dateDDMMMYYDots }}
								input.visually-hidden(:value="date.end | dateDDMMMYYDots" v-on="inputEvents.end")
				dropdown-general.ml-2(:list="dropdownList" @onChange='changeGroup')

			.activity-statistics__apexchart
				apexchart(v-if='me && me.id' type="line" height="252" :options="options" :series="series")

		.d-flex.activity-statistics__list
			.activity-statistics__item
				.d-flex.align-items-center.mb-2.mb-xl-1
					span.b3.text-grey.violet-bg.mr-2 {{ $t('project.analytics.views') }}
					svg.cursor-pointer(v-if="item.isNeuro()" :id="!isMobile ? `tooltip-views` : ''" @click="isMobile ? $bvModal.show(`tooltip-views`) : ''" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						circle(cx="12" cy="12" r="12" :fill="isDarkColor ? '#27282A' : '#FFFFFF'")
						path(d="M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z" fill="#52A7F9")
				.activity-statistics__text {{ tooltipValues.views }}
			.activity-statistics__item
				span.b3.text-grey.mb-2.mb-xl-1.primary-bg {{ $t('project.new_trial') }}
				.activity-statistics__text {{ tooltipValues.new_trial }}
			.activity-statistics__item
				span.b3.text-grey.mb-2.mb-xl-1.success-bg {{ $t('project.new') }}
				.activity-statistics__text {{ tooltipValues.new }}
			.activity-statistics__item
				.d-flex.align-items-center.mb-2.mb-xl-1
					span.b3.text-grey.p-xl-0.mr-2 {{ $t('project.conversion') }}
					svg.cursor-pointer(:id="!isMobile ? `tooltip-conversion` : ''" @click="isMobile ? $bvModal.show(`tooltip-conversion`) : ''" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none")
						circle(cx="12" cy="12" r="12" :fill="isDarkColor ? '#27282A' : '#FFFFFF'")
						path(d="M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z" fill="#52A7F9")
				.activity-statistics__text {{ total.conversion }} {{total.conversion == 0 ? '' : '%'}}

		.d-flex.activity-statistics__list
			.activity-statistics__item.activity-statistics__item--bottom
				span.b3.text-grey.mb-2.mb-xl-11.success-darkned-bg {{ $t('project.recurrent') }}
				.activity-statistics__text {{ tooltipValues.recurrent }}
			.activity-statistics__item.activity-statistics__item--bottom
				span.b3.text-grey.mb-2.mb-xl-1.error-bg {{ $t('project.refund') }}
				.activity-statistics__text {{ tooltipValues.refund }}
			.activity-statistics__item.activity-statistics__item--bottom
				span.b3.text-grey.mb-0.mb-xl-1.gray-bg {{ $t('project.cancelled') }}
				.activity-statistics__text {{ tooltipValues.cancelled }}

		modal-tooltip(
			v-if="item.isNeuro()"
			:id="`tooltip-views`"
			:classTooltip="'statistics-tooltip'"
			:title="$t('project.analytics.views')"
			:text="`${$t('tutorials.viewsDesc')}`"
			:textMobile="`${$t('tutorials.viewsDesc')}`"
		)

		modal-tooltip(
			:id="`tooltip-conversion`"
			:classTooltip="'statistics-tooltip'"
			:title="$t('project.conversion')"
			:text="item.isNeuro() ? `${$t('tutorials.conversionDesc1')}` : `${$t('tutorials.conversionDesc1')}<br><span class='text-grey mt-10 d-block'>${$t('tutorials.conversionDesc2')}</span>`"
			:textMobile="item.isNeuro() ? `${$t('tutorials.conversionDesc1')}` : `${$t('tutorials.conversionDesc1')}<span class='text-grey d-block'>${$t('tutorials.conversionDesc2')}</span>`"
		)
</template>

<script>
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { STORAGE } from '@/api/storage';
import { mapActions, mapGetters } from 'vuex';
import { PickerPeriods } from '@/constants/config';
import moment from 'moment-timezone';
import filters from '@/filters';
import { DEVICE } from '@/utils/device';

const PERIODS = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
};

let globalPeriod = PickerPeriods.DAILY;

export default {
    name: 'ActivityStatistics',
    props: {
        id: {
            type: [String, Number],
            default: '0',
        },
    },
    components: {
        DropdownGeneral,
        ModalTooltip,
    },
    data: () => ({
        date: {
            start: new Date().getTime() - 86400000 * 6,
            end: new Date(),
        },
        period: null,
        masks: {
            input: 'DD.MM.YYYY',
            weekdays: 'WW',
        },
        currentLanguage: STORAGE.getLang(),
        chartOptions: {
            stroke: {
                width: 1.5,
            },
            colors: ['#B971F3', '#52A7F9', '#59CB94', '#2A905F', '#FF7C7C', '#CBD2D8'],
            legend: {
                show: false,
            },
            tooltip: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Golos, Nunito, sans-serif',
                },
                x: {
                    format: 'dddd dd.MM.yy',
                },
                y: {
                    title: {
                        formatter(seriesName) {
                            return seriesName;
                        },
                    },
                },
            },
            plotOptions: {},
        },
        total: {},
        options: {},
        series: [],
        tooltipValues: {},
        globalLabels: [],
    }),
    created() {
        this.chartOptions.tooltip.x.formatter = this.formatter;
        this.getDropdownList();

        window.addEventListener('click', e => {
            if (this.isMobile) {
                const target = e.target;

                if (!target.closest('.apexcharts-grid')) {
                    this.setTooltipValue();
                }
            }
        });
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            activity: 'project/activity',
        }),
    },
    methods: {
        ...mapActions({
            get: 'project/activity',
        }),
        formatter(value, { w }) {
            let v = w.globals.categoryLabels[value - 1];

            if (PickerPeriods.DAILY == globalPeriod) {
                if (DEVICE.isMobile())
                    return (
                        `${filters.capitalize(this.globalLabels[value - 1].format('dd'))} ${v}.` +
                        this.globalLabels[value - 1].format('YY')
                    );
                return (
                    `<span class='apexcharts-tooltip-pre-title'">${filters.capitalize(this.globalLabels[value - 1].format('dddd'))}</span> ${v}.` +
                    this.globalLabels[value - 1].format('YY')
                );
            }

            if (PickerPeriods.MONTHLY == globalPeriod) {
                return (
                    this.globalLabels[value - 1].format('MMM').slice(0, 3) +
                    ' ' +
                    this.globalLabels[value - 1].format('YYYY')
                );
            }

            if (DEVICE.isMobile()) return `<span class="text-nowrap">${v}<br>${this.globalLabels[value - 1]._i}`;
            return `<span class="text-nowrap">${v} ${this.globalLabels[value - 1]._i}`;
        },
        getActivity() {
            this.get({
                id: this.id,
                start: moment(this.date.start).format('YYYY-MM-DDT00:00:00'),
                end: moment(this.date.end).format('YYYY-MM-DDT23:59:59'),
                group: this.period,
            }).then(this.setChart);
        },
        changeGroup(i) {
            this.period = i;
            globalPeriod = i;
            this.getActivity();
        },
        getDropdownList() {
            this.dropdownList = [
                {
                    id: 'daily',
                    title: this.$t('project.dropdownStatistics[0]'),
                },
                {
                    id: 'weekly',
                    title: this.$t('project.dropdownStatistics[1]'),
                },
                {
                    id: 'monthly',
                    title: this.$t('project.dropdownStatistics[2]'),
                },
            ];
            this.changeGroup(this.dropdownList[0].id);
        },
        findMaxNews() {
            let max = _.max(
                _.map(
                    _.filter(this.series, s => s.techName != 'views'),
                    d => _.max(d.data)
                )
            );
            return max == 0 ? 10 : Math.ceil(max * 1.05);
        },
        findMax() {
            let news = _.find(this.series, s => s.techName == 'views');
            let max = _.max(news.data);

            return max == 0 ? 10 : Math.ceil(max * 1.05);
        },
        setTooltipValue(config = false) {
            this.tooltipValues = { conversion: 0 };

            _.each(this.series, s => {
                this.tooltipValues[s.techName] =
                    !config || !this.isMobile ? this.total[s.techName] : s.data[config.dataPointIndex];
            });
        },

        setSeries() {
            let names = ['views', 'new_trial', 'new', 'recurrent', 'refund', 'cancelled'];

            this.series = _.map(names, n => {
                return {
                    techName: n,
                    name: this.$t('project.' + n),
                    data: _.values(this.activity[n]),
                };
            });
        },
        setChart() {
            this.total = {};

            _.each(this.activity, (n, i) => {
                this.total[i] = _.sum(_.values(n));
            });

            let conv =
                this.total.new_trial == 0 && this.total.new == 0
                    ? 0
                    : ((this.total.new_trial + this.total.new) * 100) / this.total.views;
            this.total.conversion = conv == 0 || conv == 100 ? conv : conv.toFixed(2);
            this.setSeries();

            this.options = {
                ...this.chartOptions,
                series: _.cloneDeep(this.series),
                grid: {
                    borderColor: this.isDarkColor ? '#444' : '#E4E7EA',
                },
                noData: {
                    text: '😢 У вас пока нет активности',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: -50,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Golos, Nunito, sans-serif',
                        color: '#8292A1',
                    },
                },
                labels: this.getChartLabels(),
                xaxis: {
                    type: 'numeric',
                    tickPlacement: 'on',
                    labels: {
                        rotate: 0,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                            fontWeight: 400,
                            colors: this.isDarkColor ? '#fff' : '#082846',
                        },
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        color: this.isDarkColor ? '#CBD2D8' : '#858B8F',
                    },
                    tooltip: {
                        enabled: this.isMobile ? true : false,
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Golos, Nunito, sans-serif',
                        },
                    },
                    tickAmount:
                        this.period == PERIODS.WEEKLY
                            ? 2
                            : this.globalLabels.length % 2 == 0
                              ? this.isMobile
                                  ? 4
                                  : 6
                              : this.isMobile
                                ? 5
                                : 7,
                    crosshairs: {
                        show: true,
                        width: 2,
                        position: 'front',
                        opacity: 0.5,
                        stroke: {
                            color: this.isDarkColor ? '#808080' : '#8292A1',
                            width: 0,
                            dashArray: 0,
                        },
                    },
                },
                yaxis: [
                    {
                        seriesName: this.$t('project.views'),
                        tickAmount: this.findMax() > 5 ? 5 : this.findMax(),
                        max: this.findMax(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                    {
                        show: false,
                        seriesName: this.$t('project.new_trial'),
                        opposite: true,
                        tickAmount: this.findMaxNews() > 5 ? 5 : this.findMaxNews(),
                        max: this.findMaxNews(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                    {
                        show: true,
                        seriesName: this.$t('project.new'),
                        opposite: true,
                        tickAmount: this.findMaxNews() > 5 ? 5 : this.findMaxNews(),
                        max: this.findMaxNews(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                    {
                        show: false,
                        seriesName: this.$t('project.recurrent'),
                        opposite: true,
                        tickAmount: this.findMaxNews() > 5 ? 5 : this.findMaxNews(),
                        max: this.findMaxNews(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                    {
                        show: false,
                        seriesName: this.$t('project.refund'),
                        opposite: true,
                        tickAmount: this.findMaxNews() > 5 ? 5 : this.findMaxNews(),
                        max: this.findMaxNews(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                    {
                        show: false,
                        seriesName: this.$t('project.cancelled'),
                        opposite: true,
                        tickAmount: this.findMaxNews() > 5 ? 5 : this.findMaxNews(),
                        max: this.findMaxNews(),
                        min: 0,
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(0);
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Golos, Nunito, sans-serif',
                                fontWeight: 400,
                                colors: this.isDarkColor ? '#808080' : '#8292A1',
                            },
                        },
                    },
                ],
                chart: {
                    id: 'activity-tatistics',
                    offsetX: 5,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    locales: [
                        {
                            name: 'en',
                            options: {
                                months: [
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                ],
                                shortMonths: [
                                    'Jan',
                                    'Feb',
                                    'Mar',
                                    'Apr',
                                    'May',
                                    'Jun',
                                    'Jul',
                                    'Aug',
                                    'Sep',
                                    'Oct',
                                    'Nov',
                                    'Dec',
                                ],
                                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                                shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            },
                        },
                        {
                            name: 'ru',
                            options: {
                                months: [
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                ],
                                shortMonths: [
                                    'Jan',
                                    'Feb',
                                    'Mar',
                                    'Apr',
                                    'May',
                                    'Jun',
                                    'Jul',
                                    'Aug',
                                    'Sep',
                                    'Oct',
                                    'Nov',
                                    'Dec',
                                ],
                                days: [
                                    'Воскресенье',
                                    'Понедельник',
                                    'Вторник',
                                    'Среда',
                                    'Четверг',
                                    'Пятница',
                                    'Суббота',
                                ],
                                shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            },
                        },
                    ],
                    events: {
                        markerClick: (event, chartContext, config) => {
                            this.setTooltipValue(config);
                        },
                        mouseMove: (event, chartContext, config) => {
                            this.setTooltipValue(config);
                        },
                    },
                    defaultLocale: this.isLocaleRu ? 'ru' : 'en',
                },
            };

            this.setTooltipValue();
        },

        getChartLabels() {
            let names = _.keys(this.activity);
            let arrMetrics = Object.keys(this.activity.views);

            if (this.period == PickerPeriods.WEEKLY && arrMetrics[0].substring(0, 4) != new Date().getFullYear()) {
                let array1 = _.map(this.activity[names[0]], (v, i) => this.getDate(i, true));
                let array2 = _.map(this.activity[names[0]], (v, i) => this.getDate(i, false));
                this.globalLabels = array1.concat(array2);
            } else {
                this.globalLabels = _.map(this.activity[names[0]], (v, i) => this.getDate(i, false));
            }

            if (PERIODS.DAILY == this.period) return _.map(this.activity[names[0]], (v, i) => moment(i).format('D.MM'));

            if (PERIODS.WEEKLY == this.period) {
                _.each(this.globalLabels, (v, i) => {
                    let d = v.clone();
                    this.globalLabels[i].l = d.format('D.MM') + ' - ' + d.add(6, 'days').format('D.MM');
                });
                return _.uniq(_.map(this.globalLabels, (v, i) => v.l));
            }

            if (PERIODS.MONTHLY == this.period)
                return _.map(this.activity[names[0]], (v, i) => moment(i).format('MMM').slice(0, 3));

            return _.map(this.activity[names[0]], (v, i) => moment(i).format('D.MM'));
        },
        getDate(d, v) {
            let yw = d.split('-');

            if (this.period == PERIODS.WEEKLY)
                if (v) {
                    return moment(yw[0]).week(Number(yw[1] - 1));
                } else {
                    return moment(yw[0]).week(Number(yw[1]));
                }

            if (this.period == PERIODS.MONTHLY) return moment(yw[0]).month(Number(yw[1]) - 1);

            return moment(d);
        },

        changeDate(v) {
            this.getActivity();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';
.apexcharts-xaxis-texts-g {
    transform: translate(15px, 10px) scale(0.96);
    @include respond-below(sm) {
        transform: translate(15px, 30px) scale(0.89);
    }
}

.activity-statistics {
    &__apexchart {
        position: relative;
        width: 150%;
        margin: 0 -26px;

        .apexcharts-tooltip {
            @include respond-below(sm) {
                display: none !important;
            }
        }
    }

    &__list {
        margin-top: 6px;

        @include respond-below(lg) {
            flex-direction: column;
            margin-top: 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        width: 25%;
        border-radius: 15px;
        background-color: var(--background-color);
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }

        @include respond-below(lg) {
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            width: 100%;
            border-radius: 0;
            background-color: transparent;
        }

        &--bottom {
            width: 33%;

            @include respond-below(lg) {
                width: 100%;
            }
        }

        span {
            position: relative;
            width: max-content;
            padding-left: 15px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }

            &.violet-bg {
                &::before {
                    background-color: var(--brand-light-gradient-secondary);
                }
            }

            &.primary-bg {
                &::before {
                    background-color: var(--brand-primary);
                }
            }

            &.success-bg {
                &::before {
                    background-color: var(--brand-success);
                }
            }

            &.success-darkned-bg {
                &::before {
                    background-color: var(--brand-bg-darkned-success);
                }
            }

            &.error-bg {
                &::before {
                    background-color: var(--brand-error);
                }
            }

            &.gray-bg {
                &::before {
                    background-color: var(--light-gray);
                }
            }
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;

        @include respond-below(lg) {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .dropdown-general .dropdown-toggle {
        height: 38px;
    }
}
</style>
