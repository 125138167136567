<template lang="pug">
	.d-flex.flex-column.basic-box-shadow.border-rounded.foreground-color.audience(v-if="stat")
		.d-flex.justify-content-between.flex-wrap.audience__header
			.d-flex.align-items-end
				span.b1 {{ $t('project.audience') }}
				svg.ml-10.cursor-pointer(:id="!isMobile ? `tooltip-audience` : ''" @click="isMobile ? $bvModal.show(`tooltip-audience`) : ''" data-v-50f1df92='' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewbox='0 0 24 24' fill='none')
					circle(data-v-50f1df92='' cx='12' cy='12' r='12' :fill="isDarkColor ? '#333333' : '#F7F7F8'")
					path(data-v-50f1df92='' d='M11.64 13.36C11.64 13.1573 11.6507 12.9707 11.672 12.8C11.704 12.6187 11.7627 12.4427 11.848 12.272C11.944 12.0907 12.072 11.904 12.232 11.712C12.4027 11.52 12.6267 11.3067 12.904 11.072C13.352 10.688 13.6933 10.3413 13.928 10.032C14.1627 9.72267 14.28 9.392 14.28 9.04C14.28 8.528 14.1093 8.10667 13.768 7.776C13.4267 7.44533 12.9573 7.28 12.36 7.28C11.7627 7.28 11.2933 7.44533 10.952 7.776C10.6107 8.10667 10.44 8.528 10.44 9.04H9C9 8.60267 9.07467 8.20267 9.224 7.84C9.384 7.46667 9.608 7.14667 9.896 6.88C10.1947 6.60267 10.552 6.38933 10.968 6.24C11.384 6.08 11.848 6 12.36 6C12.872 6 13.336 6.08 13.752 6.24C14.168 6.38933 14.52 6.60267 14.808 6.88C15.1067 7.14667 15.3307 7.46667 15.48 7.84C15.64 8.20267 15.72 8.60267 15.72 9.04C15.72 9.33867 15.672 9.62133 15.576 9.888C15.48 10.1547 15.3467 10.4107 15.176 10.656C15.016 10.8907 14.8293 11.12 14.616 11.344C14.4027 11.5573 14.184 11.76 13.96 11.952C13.7787 12.1013 13.6293 12.2453 13.512 12.384C13.3947 12.512 13.2987 12.64 13.224 12.768C13.16 12.8853 13.112 13.008 13.08 13.136C13.0587 13.2533 13.048 13.3813 13.048 13.52V13.84H11.64V13.36ZM12.36 17.52C12.0507 17.52 11.784 17.4133 11.56 17.2C11.3467 16.976 11.24 16.7093 11.24 16.4C11.24 16.08 11.3467 15.8133 11.56 15.6C11.784 15.3867 12.0507 15.28 12.36 15.28C12.6693 15.28 12.9307 15.3867 13.144 15.6C13.368 15.8133 13.48 16.08 13.48 16.4C13.48 16.7093 13.368 16.976 13.144 17.2C12.9307 17.4133 12.6693 17.52 12.36 17.52Z' fill='#52A7F9')

			.cursor-pointer.d-flex.align-items-center.audience__open-list(v-if="item && item.isNeuro() && item.isActive()" @click="$router.push({name: 'audience'})")
				svg.audience__open-list-icon(width='20' height='20' viewbox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(d='M7.35838 9.05768C5.37505 8.99102 3.80005 7.36602 3.80005 5.36602C3.80005 3.32435 5.45005 1.66602 7.50005 1.66602C9.54172 1.66602 11.2 3.32435 11.2 5.36602C11.1917 7.36602 9.61672 8.99102 7.63338 9.05768C7.55005 9.04935 7.45005 9.04935 7.35838 9.05768Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M13.675 3.33398C15.2916 3.33398 16.5916 4.64232 16.5916 6.25065C16.5916 7.82565 15.3417 9.10899 13.7833 9.16732C13.7167 9.15899 13.6417 9.15899 13.5667 9.16732' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M3.4666 17.0257C5.75827 18.559 9.5166 18.559 11.8083 17.0257C13.8249 15.6757 13.8249 13.4757 11.8083 12.134C9.52494 10.609 5.7666 10.609 3.4666 12.134C1.44993 13.484 1.44993 15.684 3.4666 17.0257Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M15.2834 16.666C15.8834 16.541 16.4501 16.2993 16.9168 15.941C18.2168 14.966 18.2168 13.3577 16.9168 12.3827C16.4584 12.0327 15.9001 11.7993 15.3084 11.666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				span.text-primary.b4.audience__open-list-txt {{ $t('project.openList') }}

		.d-flex.flex-column.flex-md-row.justify-content-between
			.d-flex.flex-column.justify-content-center.grey-border.sub-stat__item.audience__item
				span.b3.mb-1.text-center {{ $t('project.totalUsers') }}
				h3.text-center {{ stat.activated }}
			.d-flex.flex-column.justify-content-center.primary.sub-stat__item.audience__item
				span.b3.mb-1.text-center {{ $t('project.trials') }}
				h3.text-center {{ stat.trial }}
			.d-flex.flex-column.justify-content-center.success.sub-stat__item.audience__item
				span.b3.mb-1.text-center {{ $t('project.withSubscription') }}
				h3.text-center {{ stat.active }}

		modal-tooltip(
			:id="`tooltip-audience`"
			:classTooltip="'audience-tooltip'"
			:title="$t('project.audience')"
			:text="$t('project.numberUsersAndSubscriptions')"
			:textMobile="$t('project.numberUsersAndSubscriptions')"
		)

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SERVICE_STRING } from '@/utils/string';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'AudienceStatistics',
    components: {
        ModalTooltip,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
            stat: 'finance/audienceStat',
            lastOpenedProjectId: 'project/lastOpenedProjectId',
        }),
        projectUsers() {
            return SERVICE_STRING.ru_ends(this.audienceDetails[0].users, this.$t('project.users'));
        },
    },
    mounted() {
        this.getStat({ id: this.lastOpenedProjectId });
    },
    methods: {
        ...mapActions({
            getStat: 'finance/audienceStat',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.audience {
    padding: 20px;

    @include respond-below(sm) {
        padding: 14px 17px 15px;
    }

    &__header {
        margin-bottom: 20px;

        @include respond-below(sm) {
            margin-bottom: 15px;
        }
    }

    &__item {
        width: calc(100% / 3 - 20px / 3);

        @include respond-below(sm) {
            width: 100%;
        }
    }

    &__open-list {
        @include respond-below(xxs) {
            width: 100%;
            margin-top: 18px;
            margin-bottom: -2px;
        }

        &-txt {
            margin-left: 5px;
            transition: color 0.2s ease-in-out;

            @include respond-below(xs) {
                font-size: 14px !important;
            }
        }

        &-icon {
            stroke: var(--primary-text-color);
            transition: stroke 0.2s ease-in-out;
        }

        &:hover {
            .audience__open-list {
                &-txt {
                    color: var(--primary-dark-text-color) !important;
                }

                &-icon {
                    stroke: var(--primary-dark-text-color);
                }
            }
        }
    }
}
</style>
